import { UserSourceType } from '../domain/User/User/enums';
import is from '../lib/utils/is';
import { Keys, KeyValue } from '../lib/utils/types';
import { Primitive, Timestamp } from './types';

export const lastItem = <T>(arr: T[]) => arr[arr.length - 1];

export const randomString = (length = 11) => {
  let string = '';
  while (string.length < length) {
    string += Math.random().toString(36).substring(2);
  }
  return string.substring(0, length);
};

export const random = (from = 0, to = 1) => Math.floor(Math.random() * (to - from + 1)) + from;

export const randomArray = <T>(mapFn: () => T, min = 1, max = min): T[] => {
  return Array.from({ length: random(min, max) }, mapFn);
};

export const randomArrayOfStrings = (min = 1, max = min): string[] => {
  return randomArray(() => randomString(), min, max);
};

export const timestampFromNow = (ms: number): number => {
  return Date.now() + ms;
};

export const randomArrayValue = <T>(array: T[]): T => {
  return array[random(0, array.length - 1)];
};

export const randomPassword = () => randomString(5) + 'aA0';

export const randomEmail = (prefix = '') => prefix + randomString(8) + '@test.com';

export const randomIp = () => randomArray(() => random(0, 255), 4).join('.');

export const randomCountry = () => randomArrayValue([
  "United States", "United Kingdom", "France", "Germany", "Canada", "Belgium", "Austria", "Brasil"
]);

export const randomUserSource = (): UserSourceType => randomArrayValue(Object.values(UserSourceType));

export const randomUserAgent = () => `Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/${random(100, 999)}.${random(1, 99)} (KHTML, like Gecko) Chrome/${random(10, 99)}.0.${random(1000, 9999)}.100 Safari/${random(100, 999)}.${random(1, 99)}`;

export const randomPhone = () => `(${random(10e1, 10e2 - 1)})-${random(10e1, 10e2 - 1)}-${random(10e2, 10e3 - 1)}`;

export const SecondInMs = 1000;
export const MinuteInMs = SecondInMs * 60;
export const HourInMs = MinuteInMs * 60;
export const DayInMs = HourInMs * 24;
export const YearInMs = DayInMs * 365;
export const YearsInMs = (n = 1) => {
  const currentYear = new Date().getFullYear();
  const mult = (n < 0) ? -1 : 1;
  let result = 0;
  for (let i = 0; i < Math.abs(n); i++) {
    const year = currentYear + i * mult;
    let ms = YearInMs;
    if (year % 4 === 0) ms += DayInMs;
    result += ms;
  }
  return result * mult;
};

export function getLocalTimezoneOffset(localTimeInMs: number) {
  return Math.round((Date.now() - localTimeInMs) / MinuteInMs) + new Date().getTimezoneOffset();
}

const localOffset = new Date().getTimezoneOffset();

export function timestampToLocalTimezoneDate(
  timestamp = 0,
  timezoneOffset = new Date().getTimezoneOffset(),
) {
  return new Date(
    timestamp - timezoneOffset * MinuteInMs + localOffset * MinuteInMs,
  );
}

export function dateToString(date: Date): string {
  const result = [date.getDate(), date.getMonth() + 1, date.getFullYear()]
    .map(n => n.toString())
    .map(s => s.length === 1 ? `0${s}` : s)
    .join('.');

  return result;
}

export function dateFromString(str: string | number): Date | void {
  if (typeof str === 'number') {
    str = dateToString(new Date(str));
  }

  const arr = str.split('.').map(Number);
  if (arr.findIndex(n => !Number.isFinite(n)) >= 0) return;

  const [day, month, year] = arr;
  const result = new Date(year, month - 1, day);

  return result;
}

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

export function DebounceByKey<T = string>(t = 100) {
  const calls = new Map<T, { at: Timestamp, timer: NodeJS.Timer; }>();

  return (cb: () => any, key: T, delay = t) => {
    const prev = calls.get(key);
    if (prev && Date.now() - prev.at <= delay) {
      clearTimeout(prev.timer);
    }
    calls.set(key, {
      at: Date.now(),
      timer: setTimeout(() => cb(), delay),
    });
  };
};

export function debounce<T extends (...args: any[]) => any>(cb: T, delay: number) {
  let last: Timestamp | null = null;
  let timer: NodeJS.Timer | null = null;

  return (...args: Parameters<typeof cb>) => {
    let prev = last;
    last = Date.now();

    if (prev && last - prev <= delay) {
      timer && clearTimeout(timer);
    }

    timer = setTimeout(() => cb(...args), delay);
  };
};

export function centsToString(cents = 0) {
  return new Intl.NumberFormat("en-US", {
    style: 'currency',
    currency: 'USD',
  }).format(cents / 100);
}

export function createRegExpFromString(value: string): RegExp {
  const flags = value.replace(/.*\/([gimy]*)$/, '$1');
  const pattern = value.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1');

  return new RegExp(pattern, flags);
}

export function camelCaseToSentenceCase(camelCase: string): string {
  if (!camelCase) return '';
  if (camelCase.includes(' ')) return camelCase;
  const result = camelCase.replace(/([A-Z])/g, ' $1').trim();
  return result[0].toUpperCase() + result.slice(1).toLowerCase();
}

export function capitalyze(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function plural(s: string, n: number) {
  return !n || n > 1 ? `${s}s` : s;
}

export async function fileToBlob(file: File) {
  const arrayBuffer = await file.arrayBuffer();
  return new Blob([new Uint8Array(arrayBuffer)], { type: file.type });
}

export function pick<T extends KeyValue = KeyValue, K extends Partial<Record<Keys<T>, boolean>> = Partial<Record<Keys<T>, boolean>>>(obj: T, keys: K) {
  const result = {} as Pick<T, Keys<K>>;
  for (const key of Object.keys(keys)) {
    result[key] = obj[key];
  }
  return result;
}

export function omit<T extends KeyValue = KeyValue, K extends Partial<Record<Keys<T>, boolean>> = Partial<Record<Keys<T>, boolean>>>(obj: T, keys: K) {
  const result = {} as Omit<T, Keys<K>>;
  for (const [key, value] of Object.entries(obj)) {
    if (keys[key]) continue;
    result[key] = value;
  }
  return result;
}

export function uniqueBy<T>(arr: T[], by: (item: T) => Primitive) {
  const result: T[] = [];
  const keys: KeyValue<boolean> = {};
  for (const item of arr) {
    if (keys[by(item).toString()]) continue;
    keys[by(item).toString()] = true;
    result.push(item);
  }
  return result;
}

export function withoutKeys(obj: KeyValue, keys: string[]) {
  return JSON.parse(JSON.stringify(
    obj,
    (key, val) => keys.includes(key) ? undefined : val)
  );
}

export function arrayToBooleanObject(values: string[]): Record<string, boolean> {
  return values.reduce((acc, v) => {
    acc[v] = true;
    return acc;
  }, {});
};

export function escapeRegExp(value: string) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function safeParse<T = any>(value?: string | null): T | undefined {
  if (!value) return;

  try {
    return JSON.parse(value);
  } catch {
    return;
  }
};

export function deepEqual(obj1: any, obj2: any) {
  if (is.defined(obj1) !== is.defined(obj2)) return false;
  if (obj1 === obj2) return true;
  if (isPrimitive(obj1) && isPrimitive(obj2)) return obj1 === obj2;
  if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

  for (let key in obj1) {
    if (!(key in obj2)) return false;
    if (!deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

export function isPrimitive(obj: any) {
  return (obj !== Object(obj));
}
