import type { KeyValue } from './types';

export function tab(str: string, times = 1, size = 2): string {
  const tabStr = ' '.repeat(times * size);
  return (tabStr + str).replace(/\n/gm, '\n' + tabStr);
}

export default function buildString(template: string, templateFields: KeyValue<string>) {
  let result = template;
  Object.keys(templateFields).forEach((field) => {
    result = result.replace(new RegExp(`%${field}%`, 'g'), (templateFields?.[field] || ''));
  });
  result = result.replace(/%.*?%/gm, '');
  return result;
}
