const resetColor = "\x1b[0m";

const colors = {
  Bold: "\x1b[1m",
  BgRed: "\x1b[48;5;196m",

  Yellow: "\x1b[38;5;11m",
  Blue: "\x1b[38;5;12m",
  Magenta: "\x1b[38;5;13m",
  Cyan: "\x1b[38;5;14m",
  White: "\x1b[38;5;15m",
  Green: "\x1b[38;5;46m",
  Red: "\x1b[38;5;196m",
};

type ProxiedColor = {
  [key in keyof typeof colors]: (message: Printable) => string;
};

export type Printable = string | TemplateStringsArray | { toString: () => string; } | null | undefined;

const cache = {};
const color: ProxiedColor = new Proxy(colors, {
  get: (target, key: string) => {
    if (!cache[key]) {
      cache[key] = (message: Printable): string => {
        message = message?.toString()?.replaceAll(resetColor, resetColor + target[key]);
        return target[key] + message + resetColor;
      };
    }
    return cache[key];
  }
}) as any;

export default color;

export const removeColors = (str: string) => str.replace(/\x1b.*?m/g, '');
