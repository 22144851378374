import color from '../../utils/colors';
import { colors, levels } from '../log-levels';
import type { LogJSON } from '../logger';
import { messageToString } from './utils';

export default function consoleExporter({
  ts,
  level,
  pid,
  label,
  message,
}: LogJSON) {
  const levelNum = levels.indexOf(level);
  const logColor = colors[levelNum];

  message = messageToString(message);

  let text = pid ? color.Bold(`(${pid}) `) : '';
  text += label ? color.Bold(`[${label}] `) : '';
  text += logColor ? logColor(message) : message;

  if (ts) {
    const date = new Date(+ts);
    let time = [date.getHours(), date.getMinutes(), date.getSeconds(),].join(':') + '.' + date.getMilliseconds();
    time += ' '.repeat(13 - time.length);
    text = time + text;
  }

  console.log(text);
}
