import type { Callback } from '../utils/types';

export type Exporter<T = any> = Callback<[T]>;

export default abstract class Exportable<Data> {
  constructor(
    protected _exporter: Exporter<Data> | void,
  ) { }

  set exporter(exporter: Exporter<Data> | void) {
    this._exporter = exporter;
  }

  protected export(data: Data) {
    this._exporter?.(data);
  }
}