import type { LogJSON } from '../logger';

export default function browserExporter({
  ts,
  level,
  label,
  message,
}: LogJSON) {
  let text = label ? `[${label}] ` : '';

  if (ts) {
    const date = new Date(+ts);
    let time = [date.getHours(), date.getMinutes(), date.getSeconds(),].join(':') + '.' + date.getMilliseconds();
    time += ' '.repeat(13 - time.length);
    text = time + text;
  }

  console.log(`${level.toUpperCase()}:`, text, message);
}
