import { inspect } from '../utils';
import is from '../utils/is';
import { tab } from '../utils/string.builder';
import type { KeyValue } from '../utils/types';

export type ErrorJSON<T = any> = {
  name: string;
  message?: string,
  data?: T,
  stack?: string,
  originalError?: ErrorJSON | ErrorStack,
};
export type ErrorStack = {
  stack: string;
};
function isErrorStack(error: any): error is ErrorStack {
  return is.string(error.stack);
}

export default class AbstractError {
  static stackEnabled = false;
  readonly name: string;
  stack?: string;
  public constructor(
    public message: string,
    public originalError?: AbstractError | ErrorStack | Error | any,
    public data?: any,
    readonly internal?: boolean,
  ) {
    this.name = this.constructor.name || this.name;
    if (originalError && !(originalError instanceof Error || originalError instanceof AbstractError || isErrorStack(originalError))) {
      this.originalError = undefined;
      this.data = originalError;
    }
  }

  withStack(error?: Partial<ErrorStack>) {
    this.stack = error?.stack;
    if (!this.stack) {
      const stack = new Error().stack;
      this.stack = stack?.substring(stack?.indexOf('\n'));
    }
    return this;
  }

  get head() {
    let head = this.name;
    if (this.message) {
      head += `: ${this.message}`;
    }
    return head;
  }

  public toString() {
    const result = [this.head];
    if (this.data) {
      result.push(inspect(this.data));
    }
    if (this.originalError) {
      let original = '';
      if (this.originalError instanceof AbstractError) {
        original = this.originalError.toString();
      } else if (isErrorStack(this.originalError)) {
        original = this.originalError.stack;
      }
      if (original) {
        result.push(tab(original));
      }
    }
    return result.join('\n');
  }

  public toJSON(stack = AbstractError.stackEnabled): ErrorJSON {
    const data: ErrorJSON = {
      name: this.name,
      message: this.message,
    };
    if (this.data) {
      data.data = this.data;
    }
    if (stack && this.originalError) {
      if (this.originalError instanceof AbstractError) {
        data.originalError = this.originalError.toJSON();
      } else if (isErrorStack(this.originalError)) {
        data.originalError = { stack: this.originalError.stack };
      }
    }
    return data;
  }

  public toResponseJSON() {
    if (this.internal) {
      return { name: 'InternalServerError' };
    }
    return this.toJSON();
  }

  static Errors: KeyValue<typeof AbstractError> = {};

  static register(error: typeof AbstractError) {
    this.Errors[error.name] = error;
  }

  static fromJson(error?: AbstractError | Error | string | ErrorJSON | ErrorStack, message: string = ''): AbstractError {
    if (error instanceof AbstractError) return error;

    const UnknownError = this.Errors['UnknownError'];

    if (error instanceof Error || typeof error === 'string' || error == undefined) {
      return new UnknownError(message, error);
    }

    if (isErrorStack(error)) {
      return new UnknownError(error.stack);
    }

    const AbstractErrorConstructor: typeof AbstractError = this.Errors[error.name];
    if (AbstractErrorConstructor) {
      let originalError = error.originalError;
      if (originalError) {
        if (isErrorStack(originalError)) {
          originalError = originalError;
        } else {
          originalError = this.fromJson(originalError);
        }
      }
      return new AbstractErrorConstructor(error.message || '', originalError, error.data);
    }

    return new UnknownError(message, error);
  }

  static toResponseJSON(error?: AbstractError | Error | string | ErrorJSON, message: string = '') {
    return this.fromJson(error, message).toResponseJSON();
  }

  static root(error: AbstractError) {
    while (error.originalError) {
      error = error.originalError;
    }
    return error;
  }
}
