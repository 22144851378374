import { AbstractError } from '../../errors';
import { inspect } from '../../utils';
import type { Printable } from '../../utils/colors';
import type { Callback } from '../../utils/types';
import type { LogJSON } from '../logger';
import { LogLevel, LogLevelMatchVerbosity } from '../log-levels';

export function messageToString(message: Printable | Object | AbstractError, inspectDepth = 5) {
  if (message instanceof AbstractError) {
    return message.toString();
  }

  if (typeof message === 'string') return message;

  return inspect(message, false, inspectDepth);
}

export function exporterWithVerbosity(exporter: Callback<[LogJSON]>, verbosity: LogLevel) {
  return (log: LogJSON) => {
    if (!LogLevelMatchVerbosity(log.level, verbosity)) return;
    exporter(log);
  };
}
