import AbstractError from './abstract.error';
import type { ErrorJSON } from './abstract.error';

export { AbstractError, ErrorJSON };

export class BootstrapError extends AbstractError { }
AbstractError.register(BootstrapError);
export class ShutdownError extends AbstractError { }
AbstractError.register(ShutdownError);

export class InternalServerError extends AbstractError {
  public constructor(
    message: string = '',
    originalError?: AbstractError | Error | any,
    data?: any,
  ) {
    super(message, originalError, data, true);
  }
}
AbstractError.register(InternalServerError);

export class NotImplementedError extends InternalServerError { }
AbstractError.register(NotImplementedError);
export class UnknownError extends InternalServerError { }
AbstractError.register(UnknownError);
export class TimeoutError extends InternalServerError { }
AbstractError.register(TimeoutError);

export class NetworkError extends InternalServerError { }
AbstractError.register(NetworkError);
export class NetworkTimeoutError extends NetworkError { }
AbstractError.register(NetworkTimeoutError);
export class LoadBalanceError extends NetworkError { }
AbstractError.register(LoadBalanceError);

export class ClientError extends AbstractError {
  public constructor(
    message: string = '',
    originalError?: AbstractError | Error | any,
    data?: any,
  ) {
    super(message, originalError, data, false);
  }
}
AbstractError.register(ClientError);

export class WrongUsageError extends ClientError { }
AbstractError.register(WrongUsageError);
