import { AnyFn, KeyValue } from './types';

export const isBrowser = () => typeof window !== 'undefined' || typeof require === 'undefined';

const is = {
  get browser() {
    return isBrowser();
  },
  defined(v: any): boolean {
    return v != null;
  },
  string(s?: any): s is string {
    return typeof s === 'string' && Boolean(s.length);
  },
  boolean(b?: any): b is boolean {
    return typeof b === 'boolean';
  },
  number(n?: any): n is number {
    return Number.isFinite(n);
  },
  positive(n?: any): n is number {
    return is.number(n) && n > 0;
  },
  object(o?: any): o is KeyValue {
    return typeof o === 'object' && !Array.isArray(o) && o !== null;
  },
  array(v?: any): v is any[] {
    return Array.isArray(v);
  },
  function(f?: any): f is AnyFn {
    return typeof f === 'function';
  }
};

export default is;
