import is, { isBrowser } from './is';
import type { Callback, KeyValue, PromiseOr } from './types';

export const inspect = isBrowser() ? (object: any) => JSON.stringify(object) : require('util').inspect;

/**
 * asynchronously wait until a condition become true
 * @deprecated use only in tests
 * @param fn callback to wait to return true
 * @param {number} [ms=10] the callback will be called once per ms. Default = 10
 * @param {number} [timeout] throw TimeoutError after timeout ms.
 * @param {string} message the message to pass to the thrown Error
 * @returns Promise that resolves after the callback has returned true
 */
export const until = (fn: () => boolean, { ms = 10, timeout, message = '' }: { ms?: number; timeout?: number; message?: string; } = {}) => {
  let interval: NodeJS.Timer;
  return new Promise((resolve, reject) => {
    if (fn()) resolve(0);
    interval = setInterval(() => {
      if (fn()) resolve(0);
    }, ms);
    if (!timeout) return;
    setTimeout(() => {
      reject(new Error(`Timeout${message ? `: ${message}` : ''}`));
    }, timeout);
  }).finally(() => clearInterval(interval));
};

export const nsToString = (ns: bigint): string => {
  let ms = Number(ns) / 1e6;

  let s = 0;
  if (ms >= 1000) {
    s = Number.parseInt((ms / 1000).toString());
    ms %= 1000;
  }

  let m = 0;
  if (s >= 60) {
    m = Number.parseInt((s / 60).toString());
    s %= 60;
  }

  let result = '';
  if (m) {
    result += m + 'm ';
  }
  if (s) {
    result += s + 's ';
  }
  if (ms) {
    result += floatPrecision(ms, 2) + 'ms';
  }

  return result;
};

export enum Bytes {
  b = 1,
  Kb = 1024,
  Mb = 1024 ** 2,
  Gb = 1024 ** 3,
}

export const bytesToString = (bytes: number): string => {
  const mb = Math.floor(Number(bytes) / Bytes.Mb);
  bytes -= mb * Bytes.Mb;
  const kb = Math.floor(Number(bytes) / Bytes.Kb);
  bytes -= kb * Bytes.Kb;

  const result: string[] = [];
  if (mb) result.push(`${mb}Mb`);
  if (kb) result.push(`${kb}kb`);
  if (bytes) result.push(`${bytes}b`);

  return result.join(' ');
};

export const numGenerator = (i: number = 0) => {
  return {
    get current() {
      return i;
    },
    get next() {
      return (i++);
    }
  };
};

export const alphaNumGenerator = (i: number | string = 0) => {
  if (typeof i === 'string') {
    i = Number.parseInt(i, 36);
  }
  return {
    get currentNumber() {
      return i as number;
    },
    get current() {
      return i.toString(36);
    },
    get next() {
      return ((i as number)++).toString(36);
    }
  };
};

export function durationSync(fn: () => void) {
  const start = process.hrtime();
  fn();
  const [s, ns] = process.hrtime(start);
  const ms = s * 1000 + ns / 1000000;
  return ms;
}

export async function durationAsync(promise: Promise<any>) {
  const start = process.hrtime();
  await promise;
  const [s, ns] = process.hrtime(start);
  const ms = s * 1000 + ns / 1000000;
  return ms;
}

export const objectCopy = <T = any>(object: T): T => {
  return JSON.parse(JSON.stringify(object));
};

export type Lock = { unlock?: (error?: Error) => void; };
export const lock = async (object: Object & Lock): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    (object as any).unlock = (error?: Error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
      delete (object as any).unlock;
    };
  });
};

export function* stringChunkGenerator(str: string, chunkSize = 1024) {
  let i = 0;
  while (i < str.length) {
    yield str.substring(i, i + chunkSize);
    i += chunkSize;
  }
}

export function stringToChunks(str: string, chunkSize = 1024) {
  const result: string[] = [];
  let i = 0;
  while (i < str.length) {
    result.push(str.substring(i, i + chunkSize));
    i += chunkSize;
  }
  return result;
}

export const constructorName = (value: any): boolean | string => value != null && Object.getPrototypeOf(value).constructor.name;

export const percent = (sum: number, n: number, precision = 1) => {
  if (sum === 0) return 0;
  const result = n / sum * 100;
  return floatPrecision(result, precision);
};

export const floatPrecision = (num: number, precision = 1) => {
  if (!is.positive(precision)) precision = 1;
  const multiplier = 10 ** precision;
  return Math.round(num * (multiplier)) / multiplier;
};

export const random = (from = 0, to = 1) => Math.floor(Math.random() * (to - from + 1)) + from;

export const randomString = (length = 11) => {
  let string = '';
  while (string.length < length) {
    string += Math.random().toString(36).substring(2);
  }
  return string.substring(0, length);
};

export const delay = <T extends (...args: any[]) => PromiseOr<void>>(f: T, ms: number) => {
  let timer: NodeJS.Timeout | void;
  let clear = () => {
    if (timer) {
      clearTimeout(timer);
      timer = undefined;
    }
  };
  return (...args: Parameters<T>) => {
    clear();
    timer = setTimeout(() => {
      clear();
      f(...args);
    }, ms);
  };
};

/**
 * @param compareFn should return distance to target value: positive if `target > n`, negative if `target < n`, 0 if `target === n`
 * @returns index of a nearest array element according to `compareFn`
 * 
 * @example
 * binaryFindIndex(arr, (n) => target - n);
 */
export const binaryFindIndex = <T>(arr: T[], compareFn: (v: T) => number): number => {
  let left = 0, right = arr.length - 1;

  while (left < right - 1) {
    const i = Math.floor((right + left) / 2);
    const item = arr[i];
    const compared = compareFn(item);
    if (compared === 0) {
      return i;
    } else if (compared < 0) {
      right = i;
    } else {
      left = i;
    }
  }

  return left === 0 ? left : right;
};

export const avg = (nums: number[]) => {
  const sum = nums.reduce((a, b) => a + b, 0);
  return sum / nums.length;
};

export const retry = async<T>(fn: (stop: Callback) => Promise<T>, times = 1, pause = 100, factor = 2): Promise<T> => {
  let error = new Error('retry: times should be a positive number');
  let stopped = false;

  for (let i = 0; !stopped && i < times; i++) {
    try {
      const result = await fn(() => { stopped = true; });
      return result;
    } catch (e) {
      error = e;
      await new Promise((resolve) => setTimeout(resolve, pause *= factor));
    }
  }

  throw error;
};

export function regExpFromString(value: string): RegExp {
  const flags = value.replace(/.*\/([gimy]*)$/, '$1');
  const pattern = value.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1');

  return new RegExp(pattern, flags);
}

export function objectChanged(a: any, b: any) {
  const aType = typeof a;
  const bType = typeof a;
  if (aType !== bType) return true;
  if (aType !== 'object') return a !== b;
  if (!a !== !b) return true;

  const checked = {};
  for (const key of Object.keys(a).concat(Object.keys(b))) {
    if (checked[key]) continue;
    if (objectChanged(a[key], b[key])) return true;
    checked[key] = true;
  }

  return false;
}

export function objectVisitor(obj: KeyValue, cb: Callback<[value: any, keys: string[], obj: KeyValue]>, keys: string[] = []) {
  for (const [key, value] of Object.entries(obj)) {
    if (is.object(value) || is.array(value)) {
      objectVisitor(value, cb, [...keys, key]);
    } else {
      cb(value, [...keys, key], obj);
    }
  }
}
