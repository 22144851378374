import type AbstractError from '../errors/abstract.error';
import type { Printable } from '../utils/colors';
import is from '../utils/is';
import { Callback } from '../utils/types';
import Exportable from './exportable';
import { consoleExporter } from './exporter';
import { isLogLevel, LogLevelMatchVerbosity, type LogLevel } from './log-levels';

export type LogJSON = {
  ts: number;
  level: LogLevel;
  label: string;
  message: string | Object | null | undefined;
  pid?: number | string;
  tid?: string;
};

export type Exporter<T = any> = Callback<[T]>;

export default class Logger extends Exportable<LogJSON> {
  constructor(
    public label = '',
    readonly parent?: Logger,
  ) {
    super();
    if (!parent) {
      this._exporter = consoleExporter;
      this._pid = true;
      this._verbosity = Logger.defaultVerbosity;
      this.root = this;
    } else {
      this.root = parent.root;
    }
  }

  static defaultVerbosity: LogLevel = 'info';

  public child(label?: string) {
    return new Logger(label, this);
  }

  private root: Logger;
  private get isRoot() {
    return this.root === this;
  }

  get exporter() {
    return this.isRoot ? this._exporter : this.root.exporter;
  }
  set exporter(exporter: Exporter<LogJSON> | void) {
    if (this.isRoot) {
      this._exporter = exporter;
    } else {
      this.root.exporter = exporter;
    }
  }

  _pid?: boolean;
  get pid(): boolean {
    return this._pid ?? this.parent?.pid!;
  }
  set pid(pid: boolean) {
    this._pid = pid;
  }

  _verbosity: LogLevel;
  get verbosity() {
    return this._verbosity ?? this.parent?.verbosity;
  }
  set verbosity(verbosity: LogLevel) {
    if (!isLogLevel(verbosity)) {
      this.warn(`Invalid verbosity: ${verbosity}, using default: ${Logger.defaultVerbosity}`);
      return;
    }
    this._verbosity = verbosity;
  }

  public fatal(message: Printable | AbstractError | Object, label?: string) {
    this.log('fatal', message, label);
  }
  public error(message: Printable | AbstractError | Object, label?: string) {
    this.log('error', message, label);
  }
  public warn(message: Printable | Object, label?: string) {
    this.log('warn', message, label);
  }
  public info(message: Printable | Object, label?: string) {
    this.log('info', message, label);
  }
  public debug(message: Printable | Object, label?: string) {
    this.log('debug', message, label);
  }
  public trace(message: Printable | Object, label?: string) {
    this.log('trace', message, label);
  }
  public core(message: Printable | Object, label?: string) {
    this.log('core', message, label);
  }

  private log(level: LogLevel, message: Printable | Object | AbstractError, labelArg: string = '') {
    if (!LogLevelMatchVerbosity(level, this.verbosity)) return;

    const label = [this.label, labelArg].filter(Boolean).join(': ');

    const logData: LogJSON = {
      ts: Date.now(),
      level,
      label,
      message,
      pid: this.pid && !is.browser ? process.pid : undefined,
    };

    this.exporter?.(logData);
  }
}
