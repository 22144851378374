import color from '../utils/colors';

export type LogLevel =
  'disable' | // Special level to disable logs entirely. 
  'fatal' |   // One or more key business functionalities are not working and the whole system doesn't fulfill the business functionalities. 
  'error' |   // One or more functionalities are not working, preventing some functionalities from working correctly. 
  'warn' |    // Unexpected behavior happened inside the application, but it is continuing its work and the key business features are operating as expected. 
  'info' |    // An event happened, the event is purely informative and can be ignored during normal operations. 
  'debug' |   // A log level used for events considered to be useful during software debugging when more granular information is needed. 
  'trace' |   // A log level describing events showing step by step code execution that can be ignored during the standard operation, but may be useful during extended debugging sessions. 
  'core';     // Special level to monitor the AF core.

export const levels: LogLevel[] = ['disable', 'fatal', 'error', 'warn', 'info', 'debug', 'trace', 'core'];
export const colors = [, color.BgRed, color.Red, color.Yellow, color.White, color.Blue, color.Magenta, color.Green];

export function LogLevelToNumber(level: LogLevel) {
  return levels.indexOf(level);
}

export function isLogLevel(value: any): value is LogLevel {
  const index = LogLevelToNumber(value);
  return index >= 0;
}

export function LogLevelMatchVerbosity(level: LogLevel, verbosity: LogLevel) {
  return LogLevelToNumber(level) <= LogLevelToNumber(verbosity);
}
